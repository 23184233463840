import { computed, onMounted, ref, shallowRef, type ModelRef, type Ref } from 'vue'
import { debounce } from '@/services/debounce'
import { uuid } from '@/services/uuid'
import { BROWSER_SESSION_ID, sessionId } from '#main/client/src/services/api.ts'

import type { CanvasFilter, RulesSet } from '=/types'
import type { Form } from '@/services/form'

export const NEW_STONE_KEY = 'new_stone'

export const choose = ref<boolean>(false)
export const firstVisit = ref<boolean>(false)
export const selected = ref<{ name: string; index: number }>({ name: '', index: -1 })

export function closeChoose() {
  choose.value = false
  selected.value = {
    name: '',
    index: -1
  }
}

function start() {
  if (sessionId.value) return

  selected.value = {
    name: NEW_STONE_KEY,
    index: -1
  }
  choose.value = true
  firstVisit.value = true
}

export function useBricksFilter(values: ModelRef<Form<CanvasFilter>>, show: ModelRef<boolean>, input?: Ref<HTMLInputElement[]>) {
  const matchMap = shallowRef<Record<number, number>>({
    1: 100,
    2: 50,
    3: 33.33,
    4: 25
  })

  const bricksMap = computed(() => new Map(values.value.form.value.bricks.map((brick) => [brick.name, brick])))
  const checked = computed(() => (name: string) => (sessionId.value ? bricksMap.value.has(name) : false))

  function removeBrick({ name }: { name: string }) {
    const bricks = values.value.form.value.bricks
    const brick = bricksMap.value.get(name)

    if (brick && bricks.length > 1) {
      const index = bricks.indexOf(brick)
      bricks.splice(index, 1)
    }

    selected.value = {
      name: '',
      index: -1
    }
  }

  const debounceKeyUp = debounce((e: Event) => {
    if (!input) return

    const target = e.target as HTMLInputElement
    const getVal = parseFloat(target.value)

    if (getVal < 100) {
      const eachVal = (100 - getVal) / (input.value.length - 1)

      input.value.forEach((elem, index) => {
        if (elem.classList.contains('focus')) return
        values.value.form.value.bricks[index].percentage = parseInt(eachVal.toFixed(2))
      })
    }
  }, 300)

  function selectOption(name: string, index: number = -1) {
    selected.value = {
      name,
      index: index === -1 ? values.value.form.value.bricks.length : index
    }
    show.value = true
  }

  function switchSelectedBrick({ index, name, folder, example }: RulesSet['bricks'][number]) {
    const bricks = values.value.form.value.bricks

    if (bricks.map((brick) => brick.name).includes(name)) return

    if (!sessionId.value) {
      bricks.splice(0, bricks.length, {
        index,
        name,
        folder,
        percentage: matchMap.value[bricks.length] ?? 0,
        example
      })

      selected.value = {
        name: name,
        index: index
      }

      choose.value = false
      firstVisit.value = false

      sessionId.value = uuid.value
      sessionStorage.setItem(BROWSER_SESSION_ID, sessionId.value)
    } else if (selected.value.index === -1) {
      if (bricks.length >= 4) return

      bricks.push({
        index,
        name,
        folder,
        percentage: matchMap.value[bricks.length] ?? 0,
        example
      })
    } else {
      bricks.splice(selected.value.index, 1, {
        index,
        name,
        folder,
        percentage: matchMap.value[bricks.length] ?? 0,
        example
      })
    }

    selected.value = {
      name: '',
      index: -1
    }
  }

  onMounted(start)

  return {
    switchSelectedBrick,
    removeBrick,
    selectOption,
    debounceKeyUp,
    checked,
    matchMap
  }
}
