<script setup lang="ts">
import { shallowRef, type Component, type Ref } from 'vue'
import { dialog } from '@/components/modal/modal'
import { mainCanvas } from '..';

const [Wrapper, showModal, closeModal] = dialog(() => import('@/container/Example.vue'));

interface Options {
  overlay: string;
  image: Ref<string>;
  el: {
    component: Component;
    showModal: () => void;
    closeModal: () => void;
  }
}

const options = shallowRef<Options[]>([
  {
    image: mainCanvas,
    overlay: 'client/src/assets/images/BmN_product-configurator_v8_004.png',
    el: {
      component: Wrapper,
      showModal,
      closeModal
    }
  }
]);
</script>

<template>
  <div class="choose">
    <ul>
      <template v-for="({ image, overlay, el }, index) in options" :key="index">
        <template v-if="image && overlay">
          <li>
            <a @click.prevent="el.showModal()">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
                <path fill="#FFF"
                  d="M8.059 0a8.059 8.059 0 0 1 6.362 13.007l3.286 3.286a1 1 0 0 1-1.414 1.414l-3.287-3.286A8.059 8.059 0 1 1 8.06 0Zm0 2a6.059 6.059 0 1 0 4.284 10.343l.013-.012A6.059 6.059 0 0 0 8.06 2Z" />
              </svg>
              <div v-if="image.value" :style="{ backgroundImage: `url(${image.value})` }" class="content__bg" style="">
              </div>
              <img :src="overlay" class="content__img" />
            </a>
          </li>
          <component :is="el.component" v-bind="{ ...$attrs }" @close="el.closeModal()" :image :overlay size="full" />
        </template>
      </template>
    </ul>
  </div>
</template>

<style scoped>
.content__bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: repeat;
  background-size: 50px;
  z-index: -1;
}
</style>