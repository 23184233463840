import { api } from '@/services/api'

import { reactive, ref, shallowRef, type Ref } from 'vue'

import { useForm } from '@/services/form'
import { useToast } from '@/components/toast'
import { jobStatusHandlerMap, showLoadingModal, type StatusRepsonse } from './ping'
import { appendCanvasAnimated } from './animate'

import type { CanvasFilter, RulesSet } from '=/types'

export interface Canvas {
  image: string
  height: number
  width: number
}

export const mainCanvas = ref<string>('')

const { showToast, removeToast } = useToast()

const controller = {
  render: new AbortController(),
  options: new AbortController(),
  download: new AbortController()
}

const loading = reactive({
  generate: 1
})

export async function download() {
  showLoadingModal()

  const { job } = await api.get<{ job: number }>(`export`)

  const checkStatus = async () => {
    const status = await api.get<StatusRepsonse>(`status/${job}`)
    jobStatusHandlerMap[status.job.status](status, checkStatus)
  }

  checkStatus()
}

export async function useCanvas(element: Ref<HTMLCanvasElement | null>, initial: CanvasFilter) {
  const values = useForm<CanvasFilter>(initial)

  const rules = shallowRef<RulesSet>()

  async function options() {
    if (controller.options) {
      controller.options.abort()
    }

    controller.options = new AbortController()
    const { signal } = controller.options

    try {
      const data = await api.get<RulesSet>(`options`, { signal })
      rules.value = data
    } catch (error) {
      removeToast(loading.generate)
    }
  }

  async function render() {
    loading.generate = showToast({ message: 'Aan het metselen...', type: 'loading', duration: 3000 })

    if (controller.render) {
      controller.render.abort('')
    }

    controller.render = new AbortController()
    const { signal } = controller.render

    try {
      if (!rules.value) await options()

      const data = await api.post<{ canvas: Canvas; filters: CanvasFilter; options: RulesSet }>(`canvas`, {
        body: values.form.value,
        signal
      })

      appendCanvasAnimated(element, data.canvas)
      mainCanvas.value = data.canvas.image
      Object.assign(values.form, data.filters)
      rules.value = data.options
    } catch (error) {
      removeToast(loading.generate)
    } finally {
      removeToast(loading.generate)
    }
  }

  return {
    values,
    rules,
    options,
    render,
    download
  }
}
