<script setup lang="ts">
import { ref, watch } from 'vue'
import { firstVisit, NEW_STONE_KEY, useBricksFilter } from '..';

import GlobImage from '@/components/GlobImage.vue';

import type { CanvasFilter } from '=/types'
import type { Form } from '@/services/form'

const show = defineModel<boolean>('show', { required: true })
const values = defineModel<Form<CanvasFilter>>('values', { required: true })
const selected = defineModel<{ name: string, index: number }>('selected', { required: true, default: { name: '', index: -1 } })

const percentageInput = ref<HTMLInputElement[]>([]);
const focusClass = ref<Record<string, boolean>>({})

const { removeBrick, selectOption, debounceKeyUp, matchMap } = useBricksFilter(values, show, percentageInput)

watch(percentageInput, () => {
  percentageInput.value?.map((_, index) => {
    if (!percentageInput.value) return
    values.value.form.value.bricks[index].percentage = matchMap.value[percentageInput.value?.length]
  })
}, { deep: true })

</script>

<template>
  <ul class="config__grid active">
    <template v-if="firstVisit">
      <li @click.prevent="selectOption(NEW_STONE_KEY)">
        <label class="item" :class="selected.name === NEW_STONE_KEY ? 'selected new' : 'showmore'">
          <img class="item__img" />
        </label>
      </li>
    </template>
    <template v-else>
      <template v-for="(option, index) in values.form.value?.bricks" :key="index">
        <li>
          <label class="item" :class="{ selected: selected.name === option.name }">
            <input type="radio" :name="option.name" :value="option.name" :checked="selected.name === option.name"
              @input="selectOption(option.name, index)" />
            <GlobImage folder="previews" :name="option.example" class="item__img" />
            <template v-if="values.form.value.bricks.length > 1">
              <svg @click="removeBrick(option)" class="icon-close" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16.5 16.5">
                <path fill="#292524"
                  d="M8.25 0a8.25 8.25 0 0 1 8.25 8.25 8.25 8.25 0 0 1-8.25 8.25A8.25 8.25 0 0 1 0 8.25 8.25 8.25 0 0 1 8.25 0Z"
                  opacity=".75" />
                <path fill="#FAFAF9"
                  d="M6.422 5.361 8.25 7.19l1.828-1.828a.75.75 0 0 1 1.06 1.06L9.312 8.25l1.828 1.828a.75.75 0 0 1-1.061 1.06L8.25 9.312 6.422 11.14a.75.75 0 0 1-1.06-1.061L7.188 8.25 5.36 6.422a.75.75 0 0 1 1.06-1.06Z" />
              </svg>
            </template>
          </label>
          <div class="percentage">
            <div class="icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.366 9">
                <path fill="#292524"
                  d="m1.32 8.82-.96-.96L8.046.18l.96.966L1.32 8.82ZM7.206 9a2.11 2.11 0 0 1-1.086-.288 2.275 2.275 0 0 1-.78-.78 2.088 2.088 0 0 1-.294-1.092c0-.392.1-.752.3-1.08.2-.328.464-.59.792-.786a2.045 2.045 0 0 1 1.068-.294c.396 0 .758.098 1.086.294.328.192.588.452.78.78.196.324.294.686.294 1.086s-.098.764-.294 1.092a2.208 2.208 0 0 1-.78.78A2.11 2.11 0 0 1 7.206 9Zm0-1.236a.87.87 0 0 0 .456-.126.95.95 0 0 0 .342-.336.881.881 0 0 0 .126-.462.881.881 0 0 0-.126-.462.95.95 0 0 0-.342-.336.87.87 0 0 0-.456-.126.881.881 0 0 0-.462.126c-.14.084-.254.196-.342.336a.881.881 0 0 0-.126.462c0 .168.042.322.126.462.088.14.202.252.342.336.14.084.294.126.462.126ZM2.16 4.32a2.11 2.11 0 0 1-1.086-.288 2.258 2.258 0 0 1-.786-.78A2.122 2.122 0 0 1 0 2.16c0-.392.1-.752.3-1.08.2-.328.464-.59.792-.786A2.045 2.045 0 0 1 2.16 0c.396 0 .756.098 1.08.294.328.192.59.452.786.78.196.324.294.686.294 1.086s-.098.764-.294 1.092a2.258 2.258 0 0 1-.786.78 2.079 2.079 0 0 1-1.08.288Zm0-1.236a.881.881 0 0 0 .462-.126.965.965 0 0 0 .336-.336.881.881 0 0 0 .126-.462.881.881 0 0 0-.126-.462.965.965 0 0 0-.336-.336.881.881 0 0 0-.462-.126.881.881 0 0 0-.462.126.965.965 0 0 0-.336.336.881.881 0 0 0-.126.462c0 .168.042.322.126.462.084.14.196.252.336.336.14.084.294.126.462.126Z" />
              </svg>
            </div>
            <input ref="percentageInput" type="number" :class="{ focus: focusClass[index] }"
              @focus="focusClass[index] = true" @blur="focusClass[index] = false" v-model="option.percentage"
              @keyup="debounceKeyUp($event)" class="percentage__input" min="0" max="100" />
          </div>
        </li>
      </template>
    </template>
    <template v-if="values.form.value.bricks.length < 4 && !firstVisit">
      <li @click.prevent="selectOption(NEW_STONE_KEY)">
        <label class="item" :class="selected.name === NEW_STONE_KEY ? 'selected new' : 'showmore'">
          <img class="item__img" />
        </label>
      </li>
    </template>
  </ul>
</template>

<style scoped lang="scss">
.item {
  overflow: hidden;

  .item__img {
    transform: scale(3);
  }
}

.percentage__input::-webkit-outer-spin-button,
.percentage__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.percentage__input[type='number'] {
  -moz-appearance: textfield;
}
</style>